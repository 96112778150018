import * as React from 'react'
import {Button, canvas} from '@workday/canvas-kit-react'
import {SystemIcon} from '@workday/canvas-kit-react-icon'
import {downloadIcon, infoIcon} from '@workday/canvas-system-icons-web'
import styled from 'react-emotion'

interface HeroProps {
  toggleModalState: any
}

const ButtonContainer = styled(Button)({
	marginTop: canvas.spacing.xs,
	marginBottom: 0,
})
const ButtonDescriptor = styled('div')(canvas.type.small, {
	margin: `${canvas.spacing.xs} 0`,
	textAlign: 'center',
	'@media (min-width: 768px)': {
		textAlign: 'left',
	},
})
const InfoContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	'> div': {
		textAlign: 'center',
		width: '100%',
	},
	'@media (min-width: 768px)': {
		'> div': {
			textAlign: 'left',
		}
	},
	'@media (min-width: 1120px)': {
		marginTop: canvas.spacing.m,
		'p': {
			fontSize: '16px',
		},
	},
})

const InfoIcon = styled(SystemIcon)({
	marginRight: canvas.spacing.xxs,
	display: 'none',
	'@media (min-width: 768px)': {
		display: 'inline-block',
	},
})

class HeroAction extends React.Component<HeroProps> {
  render() {
		const {toggleModalState} = this.props

		const version = process.env.GATSBY_APK_FILENAME
			.match(/app-release-(.*)\.apk$/)[1]

    return (
      <>
        <ButtonContainer
          variant={Button.Variant.Primary}
          icon={downloadIcon}
          onClick={toggleModalState}
        >
          Install
        </ButtonContainer>
				<ButtonDescriptor>
					Version {version}
					<br />
					Release Date: {process.env.GATSBY_DEPLOYMENT_TIME || '29-06-2023'}
				</ButtonDescriptor>
        <InfoContainer>
          <InfoIcon
            icon={infoIcon}
            color={canvas.colors.licorice300}
          />
          { this.props.children }
        </InfoContainer>
      </>
    )
  }
}

export default HeroAction
