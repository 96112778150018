import * as React from 'react';
import styled from 'react-emotion';
import {Link} from 'gatsby';
import {colors, spacing, type} from '@workday/canvas-kit-react-core';

interface MenuProps {
  toggleModalState: any
  closeMenu?: any
}

const Menu: React.FC<MenuProps> = (props) => {
  const {toggleModalState, closeMenu} = props

  const HeaderMenu = styled('ul')({
    margin: 0,
    padding: 0,
    display: 'flex',
    '@media (max-width: 1119px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
    '@media (min-width: 1120px)': {
      flexDirection: 'row',
    },
  });
  
  const HeaderMenuItem = styled('li')({
    listStyle: 'none',
    margin: `0 ${spacing.s}`,
    padding: `0 ${spacing.xs}`,
    '@media (min-width: 1120px)': {
      '&:last-child': {
        marginRight: 0
      }
    },
  })
  
  const LinkItem = styled('a')({
    color: colors.blackPepper400,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textTransform: 'uppercase',
    '@media (max-width: 1119px)': {
      display: 'block',
      textAlign: 'center',
      width: '200px',
      marginTop: spacing.xxl
    },
  })
  
  return (
    <nav>
      <HeaderMenu>
        <HeaderMenuItem>
          <LinkItem
            href="#description"
            onClick={() => closeMenu && closeMenu()}
          >
            Features
          </LinkItem>
        </HeaderMenuItem>
        <HeaderMenuItem>
          <LinkItem 
            href="#"
            onClick={() => toggleModalState()}
          >
            Install
          </LinkItem>
        </HeaderMenuItem>
      </HeaderMenu>
    </nav>
  )
}

export default Menu