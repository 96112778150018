import * as React from 'react'
import {canvas} from '@workday/canvas-kit-react'
import {spacing} from '@workday/canvas-kit-react-core';

import styled from 'react-emotion'

const H1Header = styled('h1')({
  ...canvas.type.h1,
  fontWeight: 300,
  fontSize: '48px',
  lineHeight: '64px',
  margin: 0,
  textAlign: 'center',
  '@media (min-width: 768px)': {
    textAlign: 'left',
  },

})
const H2Header = styled('h2')({
  ...canvas.type.h2,
  fontSize: '32px',
  fontWeight: 'bold',
})

export default {
  "header-h1": H1Header,
  "header-h2": H2Header,
}
