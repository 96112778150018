import * as React from 'react';
import ReactSVG from 'react-svg';

export interface FooterProps {}
export interface FooterState {
	year: number
}

class Footer extends React.Component<FooterProps, FooterState> {
	constructor(props: FooterProps) {
		super(props);

		this.state = {
			year: new Date().getFullYear()
		}
	}
	render() {
		return (
			<footer className="wdc-marketing-footer demo-footer">
      <div className="wdc-marketing-container">
        <div className="wdc-marketing-footer-row wdc-marketing-footer-row-bottom">
          <div className="wdc-marketing-footer-green-power">
            <a
              href="//www.workday.com/en-us/company/about-workday/sustainability.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="wdc-marketing-footer-green-power-icon">
                <ReactSVG src="/icons/wd-icon-leaf.svg" />
              </div>
              Workday is 100% green powered
            </a>
          </div>
          <ul className="wdc-marketing-footer-inline-list">
            <li className="wdc-marketing-footer-privacy">
              <a
                href="//www.workday.com/en-us/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
            </li>
            <li>
              <a
                href="//www.workday.com/en-us/legal.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Legal
              </a>
            </li>
            <li className="wdc-marketing-footer-copyright">
              <span>&copy; {this.state.year} Workday, Inc.</span>
            </li>
          </ul>
        </div>
      </div>
    </footer>
		)
	}
}

export default Footer
