import * as React from 'react'
import {Button, canvas, Modal} from '@workday/canvas-kit-react'
import {colors, spacing} from '@workday/canvas-kit-react-core';
import styled from 'react-emotion'

interface ModalProps{
  toggleModalState: any,
  isOpen: boolean,
}
const ModalComponent: React.FC<ModalProps> = (props) => {
  const {isOpen, toggleModalState} = props

  const ModalContainer = styled(Modal)({
    zIndex: 3,
    '& > div, & > div > div': {
      width: '90vw',
      '@media (min-width: 768px)': {
        width: '60vw',
      },
      '@media (min-width: 1120px)': {
        width: '60vw',
      }  
    }
  })
  const LicenseHeader = styled('h3')({
    ...canvas.type.h1,
    fontSize: '24px',
    margin: `${spacing.s} ${spacing.m} ${spacing.s}`,
    '@media (min-width: 768px)': {
      margin: `${spacing.m} ${spacing.l} ${spacing.m}`,
      fontSize: '28px',
    },
    '@media (min-width: 1120px)': {
      margin: `${spacing.xl} ${spacing.xxl} ${spacing.m}`,
    }
  })

  const LicenseContainer = styled('div')({
    maxHeight: '60vh',
    margin: `0 ${spacing.m}`,
    overflowY: 'scroll',
    'p' : {
      fontSize: '12px',
    },
    '@media (min-width: 768px)': {
      margin: `0 ${spacing.l}`,
      'p' : {
        fontSize: '16px',
      },
    },
    '@media (min-width: 1120px)': {
      margin: `0 ${spacing.xxl}`,
    }

  })

  const LicenseActions = styled('div')({
    marginTop: spacing.s,
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.25)',
    padding: `${spacing.s} ${spacing.l}`,
    '@media (min-width: 1120px)': {
      marginTop: spacing.l,
      padding: `${spacing.m} ${spacing.xxl}`,
    }

  })
  const Link = styled('a')({
    color: colors.frenchVanilla100,
    textDecoration: 'none',
    '&:hover, &:active':{
      color: colors.frenchVanilla100,
    },
  })

  return (
    <ModalContainer
      width={Modal.Width.s}
      padding={Modal.Padding.zero}
      open={isOpen}
    >
      <LicenseHeader>Workday Android License Agreement</LicenseHeader>
      <LicenseContainer>{props.children}</LicenseContainer>
      <LicenseActions>
        <Button
          variant={Button.Variant.Primary}
          style={{marginRight: spacing.m}}
        >
          <Link href={`${process.env.GATSBY_ASSET_URL}/apks/${process.env.GATSBY_APK_FILENAME}`}>
            Install
          </Link>
        </Button>
        <Button
          onClick={() => toggleModalState()}
        >
          Close
        </Button>
      </LicenseActions>
    </ModalContainer>
  )
}

export default ModalComponent

