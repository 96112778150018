import * as React from 'react';
import styled from 'react-emotion';
import canvas, {colors, depth, spacing, CSSProperties} from '@workday/canvas-kit-react-core';
import {Layout} from '@workday/canvas-kit-react';

class ImageLineup extends React.Component {
	render() {
		const {children} = this.props;

		const Container = styled(Layout)({
			overflow: 'hidden',
			width: '100%',
			padding: `${spacing.m} 0 ${spacing.xl}`,
			marginTop: 0,
			'@media (min-width: 768px)': {
				padding: `${spacing.s} 0 ${spacing.xxl}`,
			},
			'@media (min-width: 1120px)': {
				padding: `115px 0`,
			},
			position: 'relative',
			zIndex: 0,
			backgroundColor: colors.frenchVanilla100,
			marginBottom: '100px',
			'&:after' : {
				content: "''",
				boxShadow: '0 8px 16px -8px rgba(0, 0, 0, 0.2)',
				display: 'block',
				height: '100%',
				background: 'linear-gradient(120deg, #0B77E3 0%, #005DBA 35%, #003B75 100%)',
				borderBottomLeftRadius: '50% 20%',
				borderBottomRightRadius: '50% 20%',
				margin: `-184px`,
				position: 'absolute',
				top: 184,
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: -1,
			}
		});

		const ImagesContainer = styled(Layout)({
			overflowX: 'scroll',
		})

		return (
			<Container>
				<ImagesContainer capWidth={true}>
					{children}
				</ImagesContainer>
			</Container>
		)
	}
}

export default ImageLineup;
