import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'react-emotion';
import canvas, {colors, spacing} from '@workday/canvas-kit-react-core';
import {Layout} from '@workday/canvas-kit-react'
import Img from 'gatsby-image';

class Hero extends React.Component {
	render() {
		const {children} = this.props;

		const HeroBackdrop = styled('div')({
			color: colors.blackPepper300 ,
			marginBottom: spacing.s,
			padding: `${spacing.l} 0 0 ${spacing.s}`,
			position: 'relative',
			zIndex: 2,
			'@media (min-width: 768px)': {
				padding: `${spacing.xl} 0 0 ${spacing.xl}`,
			},
		});

		const HeroContent = styled('div')({
			display: 'flex',
			flexDirection: 'column',
			'p': {
				...canvas.type.body,
				textAlign: 'center',
				'@media (min-width: 768px)': {
					textAlign: 'left',
				},
				'@media (min-width: 1120px)': {
					fontSize: '16px',
				},
			},
			'@media (min-width: 768px)': {
				display: 'block',
			},
		});

		const HeroImage = styled('div')({
			minWidth: 300,
			'@media (min-width: 768px)': {
				transform: 'translate(0, -20px)',
			},
			'@media (min-width: 1120px)': {
				transform: 'translate(-45px, 0px)',
			},
		});

		const HeroContentColumn = styled(Layout.Column)({
			margin: `0 ${spacing.s}`,
			'@media (min-width: 768px)': {
				margin: `0 ${spacing.m}`,
			},
			'@media (min-width: 1120px)': {
				marginRight: spacing.xxxl,
			},
		});

		const HeroImageColumn = styled(Layout.Column)({
			'@media (max-width: 768px)': {
				display: 'none',
			},
			'@media (min-width: 768px)': {
				display: 'block',
				maxWidth: '300px',
			},
			'@media (min-width: 1120px)': {
				maxWidth: '340px',
				paddingLeft: canvas.spacing.l,
				paddingRight: canvas.spacing.l,
			},
		})

		const renderElem = (data: any) => (
			<HeroBackdrop>
				<Layout capWidth={true}>
					<HeroContentColumn>
						<HeroContent className="wdc-content">
							{children}
						</HeroContent>
					</HeroContentColumn>
					<HeroImageColumn>
						<HeroImage>
							<Img fluid={data.file.childImageSharp.fluid} />
						</HeroImage>
					</HeroImageColumn>
				</Layout>
			</HeroBackdrop>
		)

		return (
			<StaticQuery
				query={graphql`
					query {
						file(name: {eq: "hero-phone"}) {
							childImageSharp {
								fluid(maxWidth:1000) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				`}
				render={renderElem}
			/>
		);
	}
}

export default Hero;

