import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'react-emotion';
import Header from './header'
import Footer from './footer'
import Modal from './Modal'
import * as rehype2react from 'rehype-react'
import registeredComponents from '../components/registered-components'

interface LayoutProps {
	isOpen: boolean,
	toggleModalState: any,
	toggleMenuState: any,
}

class Layout extends React.Component<LayoutProps> {
	render() {
		const {isOpen, toggleModalState, toggleMenuState} = this.props
		const Container = styled('div')({
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh'
		});

		const Body = styled('div')({
			flexGrow: 1
		})

		const renderAst = new rehype2react({
			createElement: React.createElement,
			components: registeredComponents
		}).Compiler;

		return (
			<StaticQuery
				query={graphql`
					query {
						licenseAgreement: markdownRemark(frontmatter: {slug: {eq: "license-agreement"}}) {
							htmlAst
						}
						site {
							siteMetadata {
								title
								favicon
								description
								url
								image
							}
						}
					}
				`}
				render={data => (
					<Container>
						<Helmet title={data.site.siteMetadata.title} bodyAttributes={{ class: "wdc-type"}}>
							<meta name="viewport" content="width=device-width, initial-scale=1.0" />
							<link rel="shortcut icon" href={data.site.siteMetadata.favicon} />
							<meta name="description" content={data.site.siteMetadata.description} />
							<meta name="image" content={data.site.siteMetadata.image} />
							<meta itemprop="name" content={data.site.siteMetadata.title} />
							<meta itemprop="description" content={data.site.siteMetadata.description} />
							<meta itemprop="image" content={data.site.siteMetadata.image} />
							<meta name="og:title" content={data.site.siteMetadata.title} />
							<meta name="og:description" content={data.site.siteMetadata.description} />
							<meta name="og:image" content={data.site.siteMetadata.image} />
							<meta name="og:site_name" content={data.site.siteMetadata.title} />
							<meta name="og:type" content="website"></meta>
						</Helmet>
						<Header 
							toggleModalState={toggleModalState}
							toggleMenuState={toggleMenuState}
						/>
						<Body>
							{this.props.children}
						</Body>
						<Footer />
						<Modal
							isOpen={isOpen}
							toggleModalState={toggleModalState}
						>
							{renderAst(data.licenseAgreement.htmlAst)}
						</Modal>

					</Container>
				)}
			/>)
	}
}

export default Layout;
