import * as React from 'react';
import Header from './canvas-kit-labs-react-header/';
import {IconButton} from '@workday/canvas-kit-react-button';
import {justifyIcon} from '@workday/canvas-system-icons-web';
import Menu from './Menu';
import useMedia from '../hooks/useMedia'

interface HeaderProps {
	toggleModalState: any
	toggleMenuState: any
}
const HeaderComponent: React.FC<HeaderProps> = (props) => {
	const title = useMedia(
    // Media queries
    ['(min-width: 1120px)', '(min-width: 768px)', '(min-width: 320px)'],
    // Modal sizes (relates to above media queries by array index)
    ['Workday Android Application', 'Workday Android Application', 'Android'],
    // Default modal size
    'Workday Android Application',
	)
	const {toggleModalState, toggleMenuState} = props
	return (
		<Header
			title={title}
			onMenuClick={toggleMenuState}
			menuToggle={(<IconButton
				icon={justifyIcon}
				className="canvas-header--menu-icon"
				aria-label="Open Menu"
			/>)}
		>
			<Menu toggleModalState={toggleModalState} />
		</Header>
	)
}

export default HeaderComponent;
