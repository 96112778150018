import * as React from 'react';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import {depth, colors, spacing} from '@workday/canvas-kit-react-core';
import {Layout as LayoutComponent} from '@workday/canvas-kit-react'
import {sortBy} from 'lodash';
import Layout from '../components/layout';
import Hero from '../components/Hero';
import HeroAction from '../components/HeroAction';
import ImageLineup from '../components/ImageLineup';
import Menu from '../components/Menu';
import * as rehype2react from 'rehype-react';
import registeredComponents from '../components/registered-components';

interface HomePageProps {
	data: {
		frontPageBlurb: {
			htmlAst: object;
		},
		appDescription: {
			htmlAst: object;
		},
		installNote: {
			htmlAst: object;
		},
		playStore: {
			htmlAst: object;
		},
		lineupImages: {
			edges: ImageEdge[]
		}
	}
}

interface ImageEdge {
	node: ImageNode
}

interface ImageNode {
	childImageSharp: {
		fixed?: object,
		fluid?: object
	}
}

const lineupOrder = ["login", "profile","orgchart", "timeoff"]

function getFilename(url: string) {
	return url.substring(url.lastIndexOf('/') + 1)
}

const IndexPage: React.FC<HomePageProps> = (props) => {
	const [modalOpen, setModalOpen] = React.useState(false);
	const [menuOpen, setMenuOpen] = React.useState(false);

	// rehype2react as any squelches a ts compiler error newing up functions as es6 modules
	const renderAst = new rehype2react({
		createElement: React.createElement,
		components: registeredComponents
	}).Compiler;

	const {appDescription, frontPageBlurb, installNote, lineupImages, playStore, playStoreImage} = props.data;

	const AppDescription = styled('div')({
		marginBottom: spacing.xxl,
		'ul > li': {
			marginBottom: spacing.xs,
		},
	});

	const ImageColumn = styled(LayoutComponent.Column)({
		margin: `${spacing.s} 0 0 ${spacing.m}`,
		'@media (min-width: 768px)': {
			margin: `${spacing.l} 0 0 ${spacing.xl}`,
		},
		'@media (min-width: 1120px)': {
		},
	})

	const MobileMenu = styled('div')({
		display: menuOpen ? 'block' : 'none',
		background: colors.frenchVanilla100,
		position: 'absolute',
		zIndex: 2,
		top: '65px',
		height: '100%',
		width: '100%',
	})

	const Subtext = styled('div')({
		fontSize: '14px',
		textAlign: 'center',
		'@media (min-width: 768px)': {
			textAlign: 'left',
		},
		'@media (min-width: 1120px)': {
			fontSize: '16px',
		},
	})

	const setModalState = () => {
		setModalOpen(!modalOpen)
		setMenuOpen(false)
	}
	const setMenuState = () => {
		setMenuOpen(!menuOpen)
	}
	const closeMenu = () => {
		setMenuOpen(false)
	}

	return (
		<Layout
			isOpen={modalOpen}
			toggleModalState={setModalState}
			toggleMenuState={setMenuState}
		>
			<Hero>
				{renderAst(frontPageBlurb.htmlAst)}
				<HeroAction toggleModalState={setModalState}>
					{renderAst(installNote.htmlAst)}
				</HeroAction>
				<Subtext>
					{renderAst(playStore.htmlAst)}
					<br />
					<a href="https://play.google.com/store/apps/details?id=com.workday.workdroidapp" target="_blank"><Img fixed={playStoreImage.childImageSharp.fixed} /></a>
				</Subtext>
			</Hero>
			<ImageLineup>
				{lineupImages.edges &&
					lineupOrder.map((imageKey, i) => {
						const {node} = lineupImages.edges.find((item) => {
							const name = getFilename(item.node.childImageSharp.fixed.src)
								.replace('.png', '')

							return name === imageKey
						})

						if (!node) {
							return null
						}

						return (
							<ImageColumn key={i}>
								<Img imgStyle={depth[3]} fixed={node.childImageSharp.fixed} />
							</ImageColumn>
						)
					})
				}
			</ImageLineup>
			<AppDescription className="wdc-marketing-container">
				<a name="description"/>
				{renderAst(appDescription.htmlAst)}
			</AppDescription>
			<MobileMenu>
				<Menu
					toggleModalState={setModalState}
					closeMenu={closeMenu}
				/>
			</MobileMenu>
		</Layout>
	)
}


export default IndexPage;

export const query = graphql`
	query {
		frontPageBlurb:	markdownRemark(frontmatter: {slug: {eq: "front-page-blurb"}}) {
			htmlAst
		}
		appDescription:	markdownRemark(frontmatter: {slug: {eq: "app-description"}}) {
			htmlAst
		}
		installNote: markdownRemark(frontmatter: {slug: {eq: "install-note"}}) {
			htmlAst
		}
		playStore: markdownRemark(frontmatter: {slug: {eq: "play-store"}}) {
			htmlAst
		}
    lineupImages: allFile(filter: {name: {in: ["login", "profile","orgchart", "timeoff"]}}) {
    	edges {
        node {
          childImageSharp {
            fixed(width: 224) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
		playStoreImage: file(relativePath: {eq: "google-play-badge.png"}) {
			childImageSharp {
				fixed(width: 201) {
					...GatsbyImageSharpFixed
				}
			}
		}
	}
`
